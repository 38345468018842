import Web3 from "web3";
import BaseServices from "./BaseServices";

export default class MetamaskService{
    static erc20Abi = [
        {
            "inputs": [],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "tokenOwner",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "spender",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "tokens",
                    "type": "uint256"
                }
            ],
            "name": "Approval",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "uint64",
                    "name": "id",
                    "type": "uint64"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "tokens",
                    "type": "uint256"
                }
            ],
            "name": "Claimed",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "_from",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "_to",
                    "type": "address"
                }
            ],
            "name": "OwnershipTransferred",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "from",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "string",
                    "name": "to",
                    "type": "string"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "tokens",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "chainId",
                    "type": "uint256"
                }
            ],
            "name": "Teleport",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "from",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "tokens",
                    "type": "uint256"
                }
            ],
            "name": "Transfer",
            "type": "event"
        },
        {
            "inputs": [],
            "name": "_totalSupply",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "acceptOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "tokenOwner",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "spender",
                    "type": "address"
                }
            ],
            "name": "allowance",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "remaining",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "spender",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokens",
                    "type": "uint256"
                }
            ],
            "name": "approve",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "success",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "spender",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokens",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "data",
                    "type": "bytes"
                }
            ],
            "name": "approveAndCall",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "success",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "tokenOwner",
                    "type": "address"
                }
            ],
            "name": "balanceOf",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "balance",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes",
                    "name": "sigData",
                    "type": "bytes"
                },
                {
                    "internalType": "bytes[]",
                    "name": "signatures",
                    "type": "bytes[]"
                }
            ],
            "name": "claim",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "toAddress",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint64",
                    "name": "",
                    "type": "uint64"
                }
            ],
            "name": "claimed",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "decimals",
            "outputs": [
                {
                    "internalType": "uint8",
                    "name": "",
                    "type": "uint8"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "name",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "newOwner",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "oracles",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "owner",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes32",
                    "name": "message",
                    "type": "bytes32"
                },
                {
                    "internalType": "bytes",
                    "name": "sig",
                    "type": "bytes"
                }
            ],
            "name": "recoverSigner",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "pure",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_newOracle",
                    "type": "address"
                }
            ],
            "name": "regOracle",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes",
                    "name": "sig",
                    "type": "bytes"
                }
            ],
            "name": "splitSignature",
            "outputs": [
                {
                    "internalType": "uint8",
                    "name": "",
                    "type": "uint8"
                },
                {
                    "internalType": "bytes32",
                    "name": "",
                    "type": "bytes32"
                },
                {
                    "internalType": "bytes32",
                    "name": "",
                    "type": "bytes32"
                }
            ],
            "stateMutability": "pure",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "symbol",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "string",
                    "name": "to",
                    "type": "string"
                },
                {
                    "internalType": "uint256",
                    "name": "tokens",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "chainid",
                    "type": "uint256"
                }
            ],
            "name": "teleport",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "success",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "thisChainId",
            "outputs": [
                {
                    "internalType": "uint8",
                    "name": "",
                    "type": "uint8"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "threshold",
            "outputs": [
                {
                    "internalType": "uint8",
                    "name": "",
                    "type": "uint8"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "totalSupply",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokens",
                    "type": "uint256"
                }
            ],
            "name": "transfer",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "success",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "tokenAddress",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokens",
                    "type": "uint256"
                }
            ],
            "name": "transferAnyERC20Token",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "success",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "from",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokens",
                    "type": "uint256"
                }
            ],
            "name": "transferFrom",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "success",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_newOwner",
                    "type": "address"
                }
            ],
            "name": "transferOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_remOracle",
                    "type": "address"
                }
            ],
            "name": "unregOracle",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint8",
                    "name": "newChainId",
                    "type": "uint8"
                }
            ],
            "name": "updateChainId",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "success",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint8",
                    "name": "newThreshold",
                    "type": "uint8"
                }
            ],
            "name": "updateThreshold",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "success",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "stateMutability": "payable",
            "type": "receive"
        }
    ]
    static web3:any;
    static MetaMaskAddress:string = "";
    static type:string = "INIT";
    static netID:string = "1";
    static events:any = {};
    static Web3Interval:any = null;
    static AccountInterval:any = null;
    static NetworkInterval:any = null;

    static emit(data)
    {
        for(var a in this.events)
        {
            this.events[a].func(data)
        }
    }

    static async checkConnection(){
        const  win:any = window;
        console.log('connection')
        if (win.ethereum) {
            win.web3 = new Web3(win.ethereum);
            try {
                await win.ethereum.enable();
                this.web3TimerCheck(win.web3);
            } catch (error) {
                this.Log( "USER_DENIED_ACCOUNT_AUTHORIZATION");
            }
        } else if (win.web3) {
            win.web3 = new Web3(win.web3.currentProvider);
            this.web3TimerCheck(win.web3);
        } else {
            this.web3 = null;
            this.Log( "NO_INSTALL_METAMASK");
            console.error(
                "Non-Ethereum browser detected. You should consider trying MetaMask!"
            );
        }
    }

    static web3TimerCheck(web3){
        this.web3 = web3;
        this.checkAccounts();
        this.checkNetWork();
        this.Web3Interval = setInterval(() => this.checkWeb3(), 1000);
        this.AccountInterval = setInterval(() => this.checkAccounts(), 1000);
        this.NetworkInterval = setInterval(() => this.checkNetWork(), 1000);
    }

    static checkAccounts() {
        if (this.web3 === null) return;
        this.web3.eth.getAccounts((err, accounts) => {
            console.log();
            if (err != null)
                return this.Log( "NETWORK_ERROR");
            if (accounts.length === 0) {
                this.MetaMaskAddress = "";
                this.Log( "NO_LOGIN");
                return;
            }
            this.MetaMaskAddress = accounts[0]; // user Address
        });
    }

    static Log( type = "") {
        const letType = type;
        if (letType === this.type) return;
        this.type = type;
        // console.log(type)
        this.emit({
            web3: this.web3,
            type,
            metaMaskAddress: this.MetaMaskAddress,
            netID: this.netID,
        });
    }

    static async checkNetWork() {
        if(this.web3.eth.net)
        {
            return this.web3.eth.net.getNetworkType().then((data)=>{
                this.Log(data)
            });
        }

        this.web3.version.getNetwork((err, netID) => {
            // Main Network: 1
            // Ropsten Test Network: 3
            // Kovan Test Network: 42
            // Rinkeby Test Network: 4
            if (err != null)
                return this.Log( "NETWORK_ERROR");
            this.netID = netID; //User MetaMask's current status
            if (this.MetaMaskAddress !== "" && netID === "1")
                return this.Log( "MAINNET");
            if (this.MetaMaskAddress !== "" && netID === "3")
                return this.Log( "ROPSTEN");
            if (this.MetaMaskAddress !== "" && netID === "42")
                return this.Log( "LOVAN");
            if (this.MetaMaskAddress !== "" && netID === "4")
                return this.Log( "RINKEBY");
            if (this.MetaMaskAddress !== "")
                this.Log( "MAINNET");
        });
    }

    static checkWeb3() {
        const win:any = window;
        let web3 = win.web3;
        if (typeof web3 === "undefined") {
            this.web3 = null;
            this.Log( "NO_INSTALL_METAMASK");
        }
    }

    static async CheckLogin(){
        //اگر لاگین باشه آی دی بر میگرده در غیر این صورت هیچی
        const win:any = window;
        if (win.ethereum) {
            win.web3 = new Web3(win.ethereum);
            this.web3 = win.web3;
            // if(win.ethereum.selectedAddress)
            // {
            return await this.getAccountData()
            // }
        }
    }

    static async getAccountData(){
        return new Promise((res,rej)=>{
            this.web3.eth.getAccounts((err, accounts) => {
                if (err != null)
                    return rej();
                if (accounts.length === 0) {
                    return res(null);
                }
                res(accounts[0]) ;
            });
        });
    }

    static async getBalance(account){
        let balance = await this.web3.eth.getBalance(account);
        return balance
    }
    static async getBalanceWithContract(contract:string , precision:number , userId:string){
        let erc = new this.web3.eth.Contract(this.erc20Abi , contract)
        let balance = await erc.methods.balanceOf(userId).call()
        return (parseFloat(balance)/Math.pow(10,precision)).toFixed(precision)
    }

    static async addTokenMetamask(token){
        let win:any = window
        try {
            const wasAdded = await win.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: token.address, // The address that the token is at.
                        symbol: token._id, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: token.digit, // The number of decimals in the token
                        image: token.image, // A string url of the token logo
                    },
                },
            });

            if (wasAdded) {
                return {status:"success" , message: "Token was added successfully!"}
            } else {
                return {status:"error" , message: "Token was not added successfully!"}
            }
        } catch (error) {
            throw error
        }
    }

    static async getChainId(){
        try {
            return this.web3.eth.getChainId()
        }catch (e) {
            throw e
        }
    }

    static async changeNetwork(chainId:number , networkInfo:any = null){
        let win:any = window
        try {
            await win.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: this.web3.utils.toHex(chainId) }]
            });
        } catch (error:any) {
            // This error code indicates that the chain has not been added to MetaMask
            if (error.code === 4902) {
                await win.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: networkInfo.networkName,
                            chainId: this.web3.utils.toHex(chainId),
                            nativeCurrency: { name: networkInfo.currencySymbol , decimals:18 , symbol: networkInfo.currencySymbol },
                            rpcUrls: [networkInfo.networkUrl]
                        }
                    ]
                });
            }
            throw error

        }
    }

    static async logout(){
        let win:any = window
        // win.web3 = await new Web3.enable({ provider: "walletconnect" });
        await win.web3.eth.currentProvider.disconnect();
    }

    static async getAllNetworks(){
        return await BaseServices.getData("https://chainid.network/chains.json")
    }
}