import { Authenticator, Chain, UAL } from "universal-authenticator-library";
// import { KeycatAuthenticator } from "@telosnetwork/ual-telos-keycat";
import { Scatter } from "ual-scatter";
import { Wombat } from 'ual-wombat'
import { Sqrl } from "@smontero/ual-sqrl";
import { Anchor } from "ual-anchor";
import {UalHamian} from 'ual-hamian'
import { HamianConfig } from 'hamian';
import Config from "@/common/Config";

 


//export default new UAL([chain], "ual", authenticators);
export  default class UalModel
{
    static ual={};
    static authenticators={};
    static init()
    {
        const appName='Ajor';
        const chain:Chain = {
            chainId: Config.chainId,
            rpcEndpoints: [
            {
                protocol: 'https',
                host: Config.walletHost,
                port: 443
            }
            ]
        }
        var hamianConfig:HamianConfig={
            useChainId:false,
            appId:'',
            appTitle: 'Ajor',
            serverUrl:'https://api.hamian-wallet.com/',
            botName:'hamianwalletbot'};

        const authenticators:Authenticator[] = [
            new UalHamian([chain], hamianConfig), 
            new Wombat([chain], { appName: appName}),
            new Anchor([chain], { appName: appName }),
            new Scatter([chain], { appName: appName }),  
            // new Sqrl([chain], { appName:appName }),
        ];
        this.ual=new UAL([chain], "ual", authenticators);
        this.authenticators=authenticators;
    }
}
