import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";

config.rawError = true;

const store = createStore({
  state:{
    selectedAccount:{},
    userAccounts:{},
    metamaskAccount:{},
    evmChainId:null,
    selectedMetamaskNetwork:null,
    metamaskNetworks:[]
  },
  actions:{
    changeSelectedAccount({commit},data){
      commit('setData',data);
    },
    setUserAccounts({commit},data){
      commit('setUserAccounts',data);
    }
  },
  mutations:{
    setData(state,data){
      state.selectedAccount = data
    },
    setUserAccounts(state,data){
      state.userAccounts = data
    },
    setMetamaskAccount(state , data){
      state.metamaskAccount = data
    },
    setEvmChainId(state , data){
      state.evmChainId = data
      let x = state.metamaskNetworks.find(nt => nt.chainId == data)      
      state.selectedMetamaskNetwork = x
    },
    setNetworks(state , data){  
      state.metamaskNetworks = data
    }
  },
  getters:{
    getSelectedAccount(state){
      return state.selectedAccount
    }
  },
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
  },
});

export default store;
