import BaseServices from "@/services/BaseServices";

export default class Config {
  static async setConfig() {
    var domain = window.location.host.split(".")[0];
    let t: any = null;
    try {
      t = await BaseServices.postData("https://api.ajor.io/web/getActiveNode");
      t = t.data.telos;
    } catch (error) {}
    if (domain == "telos-testnet") {
      this.walletHost = "testnet.telos.caleos.io";
      this.eosChainAddress = "https://testnet.telos.caleos.io";
      this.telosChainAddress = "https://testnet.telos.caleos.io";
      this.persianTelos = "https://testnet.persiantelos.com";
      this.hyperion = "https://testnet.telos.caleos.io";
      this.ajor = "https://api-telos-testnet.ajor.io";
      //this.ajor='https://api.ajor.io';
      this.trelosMainNet = "https://testnet.telos.net";
      this.network = "TLOSTestnet";
      this.chainId =
        "1eaa0824707c8c16bd25145493bf062aecddfeb56c736f6ba6397f3195f33c9f";
      this.domain = "telos-testnet";
      this.mainService = t.testnet.main ?? "https://testnet.persiantelos.com";
      this.historyService =
        t.testnet.history ?? "https://testnet.telos.caleos.io";
    } else {
      this.mainService = t
        ? t.mainnet.main
        : "https://mainnet.persiantelos.com";
      this.historyService = t ? t.mainnet.history : '"https://telos.caleos.io"';
      // this.mainService = 'https://telos.caleos.io'
      // this.historyService = 'https://telos.caleos.io'
    }
  }

  static domain: string = "telos";

  static nativeAccount: string = "tlos";
  static decimals: number = 4;

  static walletHost: string = "telos.caleos.io";
  static eosChainAddress: string = "https://telos.caleos.io";
  static telosChainAddress: string = "https://telos.greymass.com";
  static persianTelos: string = "https://mainnet.persiantelos.com";
  static hyperion: string = "https://eos.hyperion.eosrio.io";
  static server: string = "";
  static siteName: string = "Ajor";
  static username: string = "";
  static ajor: string = "https://api.ajor.io";
  static trelosMainNet: string = "https://mainnet.telos.net";

  static eosauthority: string = "https://eosauthority.com/api";
  static network: string = "TLOSMainnet";
  // static apilightxeos:string='https://api.light.xeos.me/api'
  // static megaapi:string='https://megaapi.hamian-wallet.com'
  static chainId: string =
    "4667b205c6838ef70ff7988f6e8257e8be0e1284a2f59699054a018f743b1d11";

  static mainService: string = "https://mainnet.persiantelos.com";
  static historyService: string = "https://telos.caleos.io";
}
