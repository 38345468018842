
import { defineComponent, nextTick, onMounted } from "vue";
import {mapMutations, useStore} from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import "flag-icons/css/flag-icons.min.css";
import ShowRowActions from  '@/components/custom/common/ShowRowActions.vue'
import TransactionServices from "@/services/TransactionServices"
import AccountService from "@/services/AccountServices"
import { useToast } from "vue-toastification";
import Config from "./common/Config";
import MetamaskService from '@/services/MetamaskService'


import {mapActions} from "vuex"
import UalModel from "@/services/local_service/UAL";
export default defineComponent({
  name: "app",
  components:{ShowRowActions},
  setup() {
    const store = useStore();

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });
  },

  data(){
    const toast = useToast();

    var displayDialog:any
    return {
      toast,
      showDialog:false,
      loading:true,
      actions:'',
      displayDialog,

      accountList: [],
      authenticators: {},
      selectedAccount: {},
      selectedAccountTemp:"",
    }
  },

  mounted(){
    TransactionServices.showpopUp = this.openDialog

    this.init()
  },
  
  methods:{
    ...mapActions(['setUserAccounts' , 'changeSelectedAccount']),
    ...mapMutations(['setMetamaskAccount' , 'setEvmChainId' , 'setNetworks']),

    async init(){
      this.loading = true
      try {
        await Config.setConfig()
        await UalModel.init()
        await this.checkMetamaskLogin()
        let win:any = window
        win.ethereum.on('accountsChanged' , function(accounts:any){
          window.localStorage.removeItem("jwtToken")
          window.location.reload()
        })
        
        let networks:any = await MetamaskService.getAllNetworks()      
        this.setNetworks(networks)
        
        await this.getChainId()
        win.ethereum.on('networkChanged', async (networkId) => {
          await this.checkMetamaskLogin()
          this.setEvmChainId(parseFloat(networkId))
        });

        

      } catch (error) {}
      this.loading = false
    },

    async getChainId(){
      let chainId:number = await MetamaskService.getChainId()
      this.setEvmChainId(chainId)
    },

    async checkMetamaskLogin(){
      //get logined user wallet id
      let loginData:any = await MetamaskService.CheckLogin();
      if(loginData){
        loginData = loginData.toLowerCase()
        window.localStorage.setItem("metamask_id", JSON.stringify(loginData));
        let balance:any = await MetamaskService.getBalance(loginData);
        balance = (balance.substring(0, balance.length - 12) / 1000000).toFixed(4);

        this.setMetamaskAccount({
          id:loginData,
          balance:balance
        })
      }

    },

    closeDialog(){
      this.showDialog = false
    },
    openDialog(data:any){
      this.actions=data
      this.showDialog = true
    }
  },
});
