import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ShowRowActions = _resolveComponent("ShowRowActions")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.showDialog)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ShowRowActions, {
            value: _ctx.showDialog,
            onClose: _ctx.closeDialog,
            actions: _ctx.actions
          }, null, 8, ["value", "onClose", "actions"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}