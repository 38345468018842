import ResourceData from "./ResourceData";
import GlobalService from "@/services/GlobalServices";
import AccountService from "@/services/AccountServices";
export default class Account{
	constructor(data:any)
	{
		this.data = data
		this.account_name = data.account_name;
		this.core_liquid_balance = data.core_liquid_balance;
		this.cpu_limit = data.cpu_limit;
		this.net_limit = data.net_limit;
		this.ram_limit = new ResourceData({available:data.ram_quota,used:data.ram_usage});
		this.created = data.created;
		this.total_cpu=data.total_resources.cpu_weight;
		this.total_net=data.total_resources.net_weight;
		this.total_ram=data.total_resources.ram_bytes;
		this.refund_request=data.refund_request;
		this.ram_quota=data.ram_quota;
		this.ram_usage=data.ram_usage;
		this.total_resources=data.total_resources;
		data.voter_info? this.voter_info=data.voter_info : this.voter_info = {};
		this.rex_info=data.rex_info;
		this.setBalance(data)		
		for(var a of data.permissions)
		{
			this.permissions.push(new Permission(a))
		}
	}
	data:any
	account_name?:string;
	core_liquid_balance?:string;
	balance?:any;
	//cpu data usage
	cpu_limit:ResourceData;
	//net data usage
	net_limit:ResourceData;
	ram_limit:ResourceData;
	//created date time
	created?:string;
	voter_info?:any;
	ram_quota?:any;
	ram_usage?:any;

	total_cpu?:string;
	total_net?:string;
	total_ram?:number;

	privileged?:boolean;
	cpu_weight?:number;
	net_weight?:number;
	head_block_num?:number;
	head_block_time?:string;
	last_code_update?:string;
	permissions:Permission[]=[];
	refund_request:RefoundData;
	total_resources:StakedByOthers;
	rex_info:rexInfo;

	public setBalance(data){
		let cpuRefounding = "0 TLOS"
		let netRefounding = "0 TLOS"
		let balance = "0.0"
		if( data.core_liquid_balance)balance=data.core_liquid_balance.split(" ")[0];
		let cpu = data.total_resources.cpu_weight.split(" ")[0]
		let net = data.total_resources.net_weight.split(" ")[0]
		if(data.refund_request){
			cpuRefounding = data.refund_request.cpu_amount.split(" ")[0]
			netRefounding = data.refund_request.net_amount.split(" ")[0]
		}
		let x = parseFloat(balance)+parseFloat(net)+parseFloat(cpu)+parseFloat(cpuRefounding)+parseFloat(netRefounding)
		this.balance = `${x.toFixed(4)} TLOS`
	}
}

export class Permission
{
	constructor(data:any=null)
	{
		if(!data) return
		this.parent = data.parent
		this.permName = data.perm_name
		this.requiredAuth = new RequiredAuth(data.required_auth)
	}
	parent?:string;
	permName?:string;
	requiredAuth?:RequiredAuth;
	children: Permission[];
  permissionLinks: PermissionLinks[] = [];
} 

export class PermissionLinks {
	constructor(data:any=null)
	{
		if(!data) return
		for(let link of data){
			this.links.push(new PermissionLink(link))
		}
	}
  links:PermissionLink[] = []
}
export class PermissionLink {
	constructor(data:any=null)
	{
		if(!data) return
		this.account= data.account
		this.action= data.action
		this.permission= data.permission
		this.code = data.code
	}
  account?: string;
  action?: string;
  code?: string;
  permission?: string;
}


export class RequiredAuth
{
	constructor(data:any = null){
		if(!data) return
		this.accounts = new RequiredAuthAccounts(data.accounts)
		this.keys = new RequiredAuthKeys(data.keys)
		this.waits = new RequiredAuthWaits(data.waits)
		this.threshold = data.threshold
	}
	accounts:RequiredAuthAccounts;
  keys:RequiredAuthKeys;
  threshold: number;
  waits:RequiredAuthWaits;
}
export class RequiredAuthAccounts
{
	constructor(data:any= null){		
		if(!data) return
		for(let account of data){
			this.accounts.push(new RequiredAuthAccount(account))
		}
	}
	accounts:RequiredAuthAccount[] = []
}
export class RequiredAuthAccount
{
	constructor(data:any= null){
		if(!data) return
		this.weight = data.weight
		this.permission = new RequiredAuthPerm(data.permission)
	}
	weight:number;
	permission:RequiredAuthPerm
}
export class RequiredAuthPerm
{
	constructor(data:any= null){
		if(!data) return
		this.actor = data.actor
		this.permission = data.permission
	}
	actor:string;
	permission:string
}

export class RequiredAuthKeys
{
	constructor(data:any=null){
		if(!data) return
		for(let key of data){
			this.keys.push(new RequiredAuthKey(key))
		}
	}
	keys:RequiredAuthKey[]=[]
}
export class RequiredAuthKey
{
	constructor(data:any=null){
		if(!data) return
		Object.assign(this , data)
	}
	key:string;
	weight:number;
}

export class RequiredAuthWaits
{
	constructor(data:any = null){
		if(!data) return
		for(let wait of data){
			this.waits.push(new RequiredAuthWait(wait))
		}

	}
	waits:RequiredAuthWait[] = []
}
export class RequiredAuthWait
{
	constructor(data:any = null){
		if(!data) return
		this.waitSec = data.wait_sec
		this.weight = data.weight
	}
	waitSec:number
	weight:number
}





export class RefoundData
{
	constructor(data:any)
	{
		Object.assign(this,data);
	}
	cpu_amount?:string;
	net_amount?:string;
	owner?:string;
	request_time?:string;

}
export class StakedByOthers
{
	constructor(data:any)
	{
		Object.assign(this,data);
	}
	cpu_weight?:string;
	net_weight?:string;
	owner?:string;
	ram_bytes?:string;

}
export class rexInfo
{
	constructor(data:any)
	{
		Object.assign(this,data);
	}
	vote_stake:string='';
}

export class ProfitModel{
	constructor(data:any = null){
		if(!data) return

		this.id = data.id ?? 0
		this.memo = data.memo ?? ''
		this.profit = data.profit ?? ''
		this.expirationDate = data.expiration_date ?? 0
	}
	id:number = 0
	memo:string = ''
	profit:string = ''
	expirationDate:number = 0
}


