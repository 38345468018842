
import { defineComponent } from "vue";
export default defineComponent({
  name: "dialog",
  props:{
		value:Boolean,
		actions:String
  },

  components: {
  },

  data(){
    return{
      trx:'',
      niceTrx:''
    }
  },

  methods:{
		closeDialog(){ 
			this.$emit('close')
    }, 
		copy(trx){
      var copyText:any = trx
      if(copyText)
      {
        navigator.clipboard.writeText(copyText); 
				console.log(navigator.clipboard)
      }
		},

    setActions(){
      //simple transaction
      this.trx = this.actions as string      
      //pretty transaction
      let tx = this.actions?.split("push transaction")[1].substring(1)
      tx = tx?.trim()
      tx = tx?.slice(1, -1)
      let cleosCode = this.actions?.split("push transaction")[0] as string + "push transaction "
      
      tx = JSON.parse(tx as any)
      tx = `'${JSON.stringify(tx , undefined , 3)}'`
      
      this.niceTrx = cleosCode + tx
    }
  },

  created(){
    this.setActions()
  }
});
