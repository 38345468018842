import axios from 'axios'
export default class BaseServices{

	static url= "https://apievm.areaxnft.com/api"

	static async postData(url:string,data?:any)
	{
		var path=url;
		var headers:any={};
		// if(window.localStorage.token)
		// {
		// 	headers['authorization']=window.localStorage.token
		// }
		var dt = await axios.post(path,data,{headers}); 
		return dt.data;
	}

	static async postDataWithToken(url:string,data:any)
	{
		var path=url;
		var headers:any={};
		if(window.localStorage.token)
		{
			headers['authorization']=window.localStorage.token
		}
		return  new Promise((res,rej)=>{
		  axios({ method: "POST" , "url": path , data:data , headers }).then((data)=>{
			res(data.data)
		  }).catch((err)=>{
			console.log(err)
			rej(err.response);
		  })
		})
		var dt = await axios.post(path,data,{headers}); 
		return dt.data;
	}
	
	static async getData(url:string)
	{
		var path=url;
		var headers:any={};
		// if(window.localStorage.token)
		// {
		// 	headers['authorization']=window.localStorage.token
		// }
		var dt = await axios.get(path , {headers}); 
		return dt.data;
	}

	static async getDataWithToken(url:string)
	{
		var path=url;
		var headers:any={};
		if(window.localStorage.token)
		{
			headers['authorization']=window.localStorage.token
		}
		var dt = await axios.get(path , {headers}); 
		return dt.data;
	}

	static async getDataData(url:string)
	{
		var path=url;
		var headers:any={};
		// if(window.localStorage.token)
		// {
		// 	headers['authorization']=window.localStorage.token
		// }
		var dt = await axios.get(path , {headers} ); 
		return dt.data.data;
	}

	static async getDataDataWithToken(url:string)
	{
		var path=url;
		var headers:any={};
		if(window.localStorage.token)
		{
			headers['authorization']=window.localStorage.token
		}
		var dt = await axios.get(path , {headers} ); 
		return dt.data.data;
	}
}