import Account, {
	PermissionLinks,
	Permission,
	RequiredAuthKey,
	RequiredAuthAccount,
	RequiredAuthWait,
	ProfitModel
} from "@/models/AccountModel";
import TelosProfile from "@/models/TelosProfile";
import BaseServices from "./BaseServices";
import Config from "@/common/Config"
import { new_eos_keys } from 'eos-ecc'
import TransactionServices from "./TransactionServices";
import SelectedAccountModel from "@/models/SelectedAccountModel"
import UserAccountsModel from "@/models/UserAccountsModel";
import AccountCreatorModel from "@/models/AccountCreatorModel";
export default class AccountService 
{
	static async getTableData(
		code:string,
		table:string,
		scope:string,
		lower_bound:string,
		upper_bound:string,
		limit:string,
		next:string,
		url:string=Config.mainService):Promise<any>
	{
		var l=lower_bound;
		if(next)
		{
			l=next;
		}
		var lim=parseInt(limit);
		if(next)
		{
			lim++;
		}
		var acc=await BaseServices.postData(url+'/v1/chain/get_table_rows',{
			code,
			json: true,
			index_position:"1",
			key_type: "i64",
			limit:lim,
			lower_bound:l,
			reverse: false,
			scope,
			table,
			table_key:"",
			upper_bound
		});
		if(next)
		{
			acc.rows.splice(0,1)
		}
		return  acc; 
		
	}
	static async getAbi(accountName:string,url:string=Config.historyService):Promise<any>
	{
		var acc=await BaseServices.postData(url+'/v1/chain/get_abi',{account_name:accountName});
		return  acc;
	}
	static async getAccount(accountName:string,url:string=Config.mainService):Promise<Account>
	{
		var acc=await BaseServices.postData(url+'/v1/chain/get_account',{account_name:accountName});
		return new Account(acc);
	}
	static async getBalance(accountName:string,contract:string='eosio.token'){
		let json = {
			code: contract,
			index_position: 1,
			json: true,
			key_type: "",
			limit: "100",
			lower_bound: null,
			reverse: false,
			scope: accountName,
			show_payer: false,
			table: "accounts",
			upper_bound: null
		}
		return BaseServices.postData(Config.mainService+'/v1/chain/get_table_rows',json)
	}
	static async getRexBalance(accountName:string)
	{
		var rex=0;

		let json = {
			code: 'eosio',
			index_position: 1,
			json: true,
			key_type: "",
			limit: "1",
			lower_bound: accountName,
			reverse: false,
			scope: 'eosio',
			show_payer: false,
			table: "rexbal",
			upper_bound: accountName
		}
		var balance=await BaseServices.postData(Config.mainService+'/v1/chain/get_table_rows',json)
		if(balance.rows.length)
		{
			rex+=parseFloat(balance.rows[0].vote_stake.split(' ')[0])
		}
		json = {
			code: 'eosio',
			index_position: 1,
			json: true,
			key_type: "",
			limit: "1",
			lower_bound: accountName,
			reverse: false,
			scope: 'eosio',
			show_payer: false,
			table: "rexfund",
			upper_bound: accountName
		}
		var balance=await BaseServices.postData(Config.mainService+'/v1/chain/get_table_rows',json)
		if(balance.rows.length)
		{
			rex+=parseFloat(balance.rows[0].balance.split(' ')[0])
		}
		return rex
	}
	static async getCollectors(accountName:string){
		return BaseServices.getData(`https://api1.areaxnft.com/web/getCollectors?$filter=_id%20eq%20%27${accountName}%27`)
	}

	static saveAccount(accountName:string,permission:string,walletIndex:any,balance:any=null){
		if(localStorage.getItem('userAccounts')){
			let userAccounts:any = localStorage.getItem('userAccounts')
			userAccounts = JSON.parse(userAccounts)
			var accountExist=false ;
			for(let acc of userAccounts){
				if(acc.walletIndex == walletIndex && acc.accountName == accountName){
					console.log('account exist')
					// this.setSelectedAccount(accountName,permission,walletIndex,balance)
					accountExist = true;
				}
			}
			if(!accountExist){
				userAccounts.push({accountName:accountName,permission:permission,walletIndex:walletIndex,balance:balance})
				localStorage.setItem('userAccounts',JSON.stringify(userAccounts))
				if(localStorage.getItem('userAccounts')){
					return{message:'success'}
				}
			}
		}else{
			let userAccounts:any = [{accountName:accountName,permission:permission, walletIndex:walletIndex,balance:balance}]
			// this.setSelectedAccount(accountName,permission,walletIndex,balance)
			localStorage.setItem('userAccounts',JSON.stringify(userAccounts))
		}
	}
	static async getUserAccounts(){
		if(localStorage.getItem('userAccounts')){
			let userAccounts:any = localStorage.getItem('userAccounts')
			userAccounts = new UserAccountsModel(JSON.parse(userAccounts))
			return {data:userAccounts,message:'success'}
		}
		else{
			return {data:null,message:'failed'}
		}
	}
	static setSelectedAccount(accountName:string,permission:string,walletIndex:number,balance:any){
		let userAccounts:any = {accountName:accountName,permission:permission, walletIndex:walletIndex,balance:balance}
		localStorage.setItem('selectedAccount',JSON.stringify(userAccounts))
		if(localStorage.getItem('selectedAccount')){
			return{message:'success'}
		}
	}
	static async getSelectedAccount(){
		if(localStorage.getItem('selectedAccount')){
			let selectedAccount:any = localStorage.getItem('selectedAccount')
			selectedAccount = new SelectedAccountModel(JSON.parse(selectedAccount))
			return {data:selectedAccount,message:'success'}
		}
		else{
			return {data:null,message:'failed'}
		}
	}
	static removeAccount(id:number,accountName:string){
		if(localStorage.getItem('userAccounts')){
			let list:any = localStorage.getItem('userAccounts')
			list = JSON.parse(list)
			for(let acc in list){
				if(list[acc].walletIndex == id && list[acc].accountName == accountName){
					list.splice(acc, 1)
					list = JSON.stringify(list)
					localStorage.setItem('userAccounts',list)
				}
			}
		}
		if(localStorage.getItem('selectedAccount')){
			let selectedAccount:any = localStorage.getItem('selectedAccount')
			selectedAccount = JSON.parse(selectedAccount)
			if(id == selectedAccount.walletIndex && accountName == selectedAccount.accountName){
				localStorage.removeItem('selectedAccount');
				let list:any = localStorage.getItem('userAccounts')
				list = JSON.parse(list)
				if(list.length>0){
					this.setSelectedAccount(list[0].accountName,list[0].permission,list[0].walletIndex,list[0].balance)
				}
			}
		}
	}

	static getPublicAndPrivateKey()
	{
		return new_eos_keys()
	}
	static async createAccount(selectedAccountName:string,selectedAccountPermission:string,newAccountName:string,publicKey:string){
		{
			let actions:any=[
				{
					"account":"eosio",
					"name":"newaccount",
					"authorization":[
					{"actor":selectedAccountName,"permission":selectedAccountPermission}],
					"data":{
						"creator":selectedAccountName,
						"name":newAccountName,
						"owner":{
							"threshold":1,
							"keys":[{"key":publicKey,"weight":1}],
							"accounts":[],
							"waits":[]
						},
						"active":{
							"threshold":1,
							"keys":[{"key":publicKey,"weight":1}],
							"accounts":[],
							"waits":[]
						}
					}
				},
				{
					"account":"eosio",
					"name":"buyrambytes",
					"authorization":[{"actor":selectedAccountName,"permission":selectedAccountPermission}],
					"data":{"payer":selectedAccountName,"receiver":newAccountName,"bytes":1800}
				},
				{
				"account":"eosio",
				"name":"delegatebw",
				"authorization":[{"actor":selectedAccountName,"permission":selectedAccountPermission}],
				"data":{
					"from":selectedAccountName,
					"receiver":newAccountName,
					"stake_net_quantity":"0.0500 TLOS",
					"stake_cpu_quantity":"1.0000 TLOS",
					"transfer":false}}
				];
			try {
				console.log(actions)
				return await TransactionServices.runTransaction(actions)
			} catch (error:any) {
				return {message:error.message}
			}
		}
	}
	static async createAdvancedAccount(selectedAccountName:string,newAccountName:string,publicOwnerKey:string,publicActiveKey:string,netStake:string,cpuStake:string,ramBuyForNewAccount:number,transfer:boolean,selectedAccountPermission:string='active'){
		let actions = [
			{
				"account": "eosio",
				"name": "newaccount",
				"data": {
				"creator": selectedAccountName,
				"name": newAccountName,
				"owner": {"threshold": 1,"keys": [{"key": publicOwnerKey,"weight": 1}],"accounts": [],"waits": []},
				"active": {"threshold": 1,"keys": [{"key": publicActiveKey,"weight": 1}],"accounts": [],"waits": []}
				},
				"authorization": [{"actor": selectedAccountName,"permission": selectedAccountPermission}]
			},
			{
				"account": "eosio",
				"name": "buyrambytes",
				"data": {
				"payer": selectedAccountName,
				"receiver": newAccountName,
				"bytes": ramBuyForNewAccount
				},
				"authorization": [{"actor": selectedAccountName,"permission": selectedAccountPermission}]
			},
			{
				"account": "eosio",
				"name": "delegatebw",
				"data": {
				"from": selectedAccountName,
				"receiver": newAccountName,
				"stake_net_quantity": netStake,
				"stake_cpu_quantity": cpuStake,
				"transfer": transfer
				},
				"authorization": [{"actor": selectedAccountName,"permission": selectedAccountPermission}]
			}
		]
		try {
			console.log(actions)
			return await TransactionServices.runTransaction(actions)
		} catch (error:any) {
			return {message:error.message}
		}
	}
	static saveAccountVIACli(accountName:string,permission:string,walletIndex:string,balance:string=''){
		let accInfo:any = {
			accountName:accountName,
			permission:permission,
			walletIndex:walletIndex,
			balance:balance,
		}
		accInfo = JSON.stringify(accInfo)
		localStorage.setItem('selectedAccount',accInfo)
	}

	static async getCreatorAccount(accountName:string){
		let creatorInfo = await BaseServices.getData(`${Config.historyService}/v2/history/get_creator?account=${accountName}`)
		return new AccountCreatorModel(creatorInfo)
	}

	//telos profile
	static async getTelosProfile(accountName:string):Promise<TelosProfile>{
		let json = {
			"code": "profiles",
			"scope": "profiles",
			"table": "profiles",
			"table_key": "",
			"lower_bound": accountName,
			"upper_bound": accountName,
			"limit": "10",
			"key_type": "i64",
			"index_position": "1",
			"json": true,
			"reverse": false
		}
		let t = await BaseServices.postData(Config.mainService+'/v1/chain/get_table_rows',json)
		return new TelosProfile(t.rows[0]) 
	}
	static async createTelosProfile(data:any){
		let actions = [
			{
				"account":"profiles",
				"name":"newprofile",
				"authorization":[
					{
					"actor":data.accountName,
					"permission":"active"
					}
				],
				"data":{
					"account":data.accountName,
					"display_name":data.displayName,
					"avatar":data.avatar,
					"bio":data.bio,
				}
			}
		]
		await TransactionServices.runTransaction(actions)
	}
	static async editDisplayName(accountName:string , displayName:string){
		let actions = [
			{
				"account":"profiles",
				"name":"editdisplay",
				"authorization":[
					{
					"actor":accountName,
					"permission":"active"
					}
				],
				"data":{
					"account":accountName,
					"new_display_name":displayName
				}
			}
		]
		await TransactionServices.runTransaction(actions)
	}
	static async editStatus(accountName:string , status:string){
		let actions = [
			{
				"account":"profiles",
				"name":"editstatus",
				"authorization":[
					{
					"actor":accountName,
					"permission":"active"
					}
				],
				"data":{
					"account":accountName,
					"new_status":status
				}
			}
		]
		await TransactionServices.runTransaction(actions)
	}
	static async editBio(accountName:string , bio:string){
		let actions = [
			{
				"account":"profiles",
				"name":"editbio",
				"authorization":[
					{
					"actor":accountName,
					"permission":"active"
					}
				],
				"data":{
					"account":accountName,
					"new_bio":bio
				}
			}
		]
		await TransactionServices.runTransaction(actions)
	}
	static async editAvatar(accountName:string , avatar:string){
		let actions = [
			{
				"account":"profiles",
				"name":"editavatar",
				"authorization":[
					{
					"actor":accountName,
					"permission":"active"
					}
				],
				"data":{
					"account":accountName,
					"new_avatar":avatar
				}
			}
		]
		await TransactionServices.runTransaction(actions)
	}

	static async simpleChangePermision(
		accountName:string, //user current account
		permission:string, //user current permission
		active:boolean, //user wants to change active key
		owner:boolean, //user wants to change owner key 
		activeKey:string, //selected active key to change
		ownerKey:string, //selected owner key to change
		){

		let actions:any[] = []

		if(active){
			actions.push(
				{
					"account": "eosio",
					"name": "updateauth",
					"data": {
						"account": accountName,
						"permission": "acitve",
						"parent": "owner",
						"auth": {
							"threshold": 1,
							"keys": [{"key": activeKey,"weight": 1}],
							"accounts": [],
							"waits": []
						}
					},
					"authorization": [{"actor": accountName,"permission": permission}]
				}
			)
		}

		if(owner){
			actions.push(
				{
					"account": "eosio",
					"name": "updateauth",
					"data": {
						"account": accountName,
						"permission": "owner",
						"parent": "",
						"auth": {
							"threshold": 1,
							"keys": [{"key": ownerKey,"weight": 1}],
							"accounts": [],
							"waits": []
						}
					},
					"authorization": [{"actor": accountName,"permission": permission}]
				}
			)
		}

		await TransactionServices.runTransaction(actions)
  	
	}
	
	static async advancedChangePermission(
		accountName:string, //user current account
		accountPermission:string, //user current permission
		permissionName:string, //selected name
		permissionParent:string,// selected parent
		permissionTreshold:number, //treshold
		keys: RequiredAuthKey[], //added keys
		waits: RequiredAuthWait[], //added waits
		accounts: RequiredAuthAccount[], //added accounts
		){
		
		//TODO:keys struc 
		
		// let keys=[
		// 	{"key": "PUB_K1_4yv3sL15w2NvtrSDG46h1z5RinP7yAmxZZJm5zTM1ADbtvd5a3","weight": 1},
		// 	{"key": "PUB_K1_72XXv5CYHJQGBAvoWxKWKCkfuend9nccdiNXPE59G6EeYL1yD2","weight": 1}
		// ]

		//TODO:accounts struc 

		// let accounts= [
		// 	{
		// 		"permission": {
		// 			"actor": accountName,
		// 			"permission": accountPermission
		// 		},
		// 		"weight": 1
		// 	},
		// 	{
		// 		"permission": {
		// 			"actor": accountName,
		// 			"permission": accountPermission
		// 		},
		// 		"weight": 1
		// 	}
		// ],

		// TODO:waits struc
		// let waits = [
		// 	{
		// 		"wait_sec": 100,
		// 		"weight": 1
		// 	},
		// 	{
		// 		"wait_sec": 20,
		// 		"weight": 2
		// 	}
		// ]

		let actions= [
			{
				"account": "eosio",
				"name": "updateauth",
				"data": {
					"account":accountName,
					"permission": permissionName,
					"parent": permissionParent,
					"auth": {
						"threshold": permissionTreshold,
						"keys": keys,
						"accounts": accounts,
						"waits": waits
					}
				},
				"authorization": [
					{
						"actor": accountName,
						"permission": accountPermission
					}
				]
			}
		]
		try {
      await TransactionServices.runTransaction(actions)
  	} catch (error:any) {
			throw error
  	}
	}

	static async advanceDeletePermission(
		accountName:string,
		accountPermission:string,
		selectedPermission:string
	){
		let actions:any[] =[
			{
				account: "eosio",
				name: "deleteauth",
				data: {
					account: accountName,
					permission: selectedPermission
				},
				authorization: [
					{
						actor: accountName,
						permission: accountPermission
					}
				]
			}
		]
		try {
      await TransactionServices.runTransaction(actions)
  	} catch (error:any) {
			throw error
  	}
	}

	static async linkAuth(
		accountName:string,  // current user account
		code:string,  // contract name
		type:string,  // contract action
		requirement:string,  // permission (only needed for linking)
		permision:string='active'  // current user account permission
		){
		let actions=[
			{
				"account": "eosio",
				"name": "linkauth",
				"data": {
					"account": accountName,
					"code": code,
					"type": type,
					"requirement":requirement
				},
				"authorization": [{"actor": accountName,"permission": permision}]
			}
		]
		try {
      await TransactionServices.runTransaction(actions)
		} catch (error:any) {
			return {message:error.message}
		}
	}

	static async unLinkauth(
		accountName:string,  // current user account
		code:string,  // contract name
		type:string,  // contract action
		permision:string='active'  // current user account permission
		){
		let actions=[
			{
				"account": "eosio",
				"name": "unlinkauth",
				"data": {
					"account": accountName,
					"code": code,
					"type": type,
				},
				"authorization": [{"actor": accountName,"permission": permision}]
			}
		]
		try {
      await TransactionServices.runTransaction(actions)
		} catch (error:any) {
			return {message:error.message}
		}
	}

	static async getPermissionsLink(account:string):Promise<PermissionLinks>{
		let res = await BaseServices.getData(`${Config.historyService}/v2/state/get_links?account=${account}`)

		return new PermissionLinks(res.links)
	}

	static async getProfitList(account:string , skip:number = 0){
		let json:any = {
			"code": "profits.pe",
			"json": true,
			"index_position": "1",
			"key_type": "i64",
			"limit": 30,
			"lower_bound": skip,
			"reverse": false,
			"scope": account,
			"table": "profits",
			"table_key": "",
		}

		let t:any = await BaseServices.postData(`${Config.mainService}/v1/chain/get_table_rows` , json)

		let items:ProfitModel[] = []
		for(let item of t.rows){
			items.push(new ProfitModel(item))
		}

		return {
			more: t.more,
			nextKey: t.next_key,
			items: items
		}
	}

	static async claimProfit(account:any , profitId:number){
		console.log(account)
		let actions:any = [
			{
				"account": "profits.pe",
				"name": "claimprofit",
				"data": {
					"account": account.accountName,
					"profit_id": profitId,
				},
				"authorization": [{"actor": account.accountName,"permission": account.permission}]
			}
		]
		try {
			await TransactionServices.runTransaction(actions)
		} catch (error:any) {
			throw error
		}
	}
}