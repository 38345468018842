import GlobalService from '@/services/GlobalServices'
export default class BlockModel
{
	constructor(data:any){
		this.actionMroot = data.action_mroot
		this.blockNum = data.block_num
		this.confirmed = data.confirmed
		this.id = data.id
		this.newProducers = data.new_producers
		this.previous = data.previous
		this.producer = data.producer
		this.producerSignature = data.producer_signature
		this.refBlockPrefix = data.ref_block_prefix
		this.schedulVersion = data.schedule_version
		this.timestamp = data.timestamp
		this.transactionMroot = data.transaction_mroot
		this.transactions = data.transactions
	}
	actionMroot:string=""
	blockNum:number=0
	confirmed:number=0
	id:string=""
	newProducers?:any
	previous:string=""
	producer:string=""
	producerSignature:string=""
	refBlockPrefix:number=0
	schedulVersion:number=0
	timestamp:string=""
	transactionMroot:string=""
	transactions:any[]=[]
} 

