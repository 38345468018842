export default class AccountCreatorModel
{
	constructor(data:any){
		this.account = data.account
        this.block_num = data.block_num
        this.cached = data.cached
        this.creator = data.creator
        this.query_time_ms = data.query_time_ms
        this.timestamp = data.timestamp
        this.trx_id = data.trx_id
	}
	account?:string
    block_num?:number=0
    cached?:boolean=true
    creator?:string
    query_time_ms?:number=0
    timestamp?:string
    trx_id?:string
} 