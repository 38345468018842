import ResourceData from "./ResourceData";
import GlobalService from "@/services/GlobalServices";
import AccountService from "@/services/AccountServices";
export default class Account{
	constructor(data:any)
	{
		this.accountName = data.account_name
		this.avatar = data.avatar
		this.bio = data.bio
		this.displayName = data.display_name
		this.isVerified = data.is_verified
		this.status = data.status
	}
	accountName:string=''
	avatar?:string=''
	bio?:string=''
	displayName?:string=''
	isVerified?:string=''
	status?:string=''
}