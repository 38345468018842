export default class SelectedAccountModel
{
	constructor(data:any){
		this.accountName = data.accountName
		this.balance = data.balance
		this.permission = data.permission
		this.walletIndex = data.walletIndex
	}
	accountName?:string
    balance?:string
    permission?:string
    walletIndex?:any
} 