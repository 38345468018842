import ual from "./local_service/UAL";
import Config from "@/common/Config";
import AccountService from "./AccountServices";
import BaseServices from "./BaseServices";
import TransactionsModel from "@/models/transactions_Models/TransactionsModel";
import SingelTransactionModel from "@/models/transactions_Models/SingelTransactionModel";
import BlockModel from "@/models/transactions_Models/blockModel";

export default class TransactionServices {
  static showpopUp?: any;
  static async runTransaction(actions: any[]) {
    let acc: any = await AccountService.getSelectedAccount();
    for (let action of actions) {
      if (!action.authorization) {
        action.authorization = [
          {
            actor: acc.data.accountName,
            permission: acc.data.permission,
          },
        ];
      }
    }

    if (acc.data.walletIndex == "cli") {
      // console.log(JSON.stringify(actions))
      // let withSingleQouate = JSON.stringify(actions)
      // let withoutSingleQouate = withSingleQouate.replaceAll("'","\\'");
      // console.log(withoutSingleQouate)
      let text: any =
        `cleos -u ${Config.mainService} push transaction '{"delay_sec":0,"actions":` +
        JSON.stringify(actions) +
        `}'`;
      return this.showpopUp(text);
    } else {
      try {
        let index = acc.data.walletIndex;
        var authenticator = ual.authenticators[index];
        await authenticator.init();
        const users = await authenticator.login(Config.username);
        const account = users[0];
        await account.signTransaction(
          {
            actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 30,
          }
        );
      } catch (e) {
        console.log("error ====>", e);
        throw e;
      }
    }
  }
  // FIXME:it work only with anchor
  static async voteBPS(voter: string, permission: string, producers: any) {
    let actions: any = [
      {
        account: "eosio",
        name: "voteproducer",
        authorization: [
          {
            actor: voter,
            permission: permission,
          },
        ],
        data: {
          voter: voter,
          proxy: "",
          producers: producers,
        },
      },
    ];
    try {
      console.log("actions", actions);
      await this.runTransaction(actions);
    } catch (error: any) {
      return { message: error.message };
    }
  }

  static async getTransactions(
    accountName: string,
    filter: any,
    lastId: number = 0
  ): Promise<TransactionsModel> {
    var offset = -50;
    var pos = 50;
    if (lastId) {
      pos = 50 * (lastId + 1); //74414
      offset = -49;
    }
    let json = {
      account_name: accountName,
      offset: offset,
      pos: pos,
    };

    //set filter
    if (filter) {
      for (let x in filter) {
        json[x] = decodeURIComponent(filter[x]);
      }
    }
    let transactions: any = await BaseServices.postData(
      `${Config.historyService}/v1/history/get_actions`,
      json
    );
    return new TransactionsModel(transactions, accountName);
  }

  static async getTransaction(
    transactionId: string
  ): Promise<SingelTransactionModel> {
    let result: any = await BaseServices.postData(
      `${Config.historyService}/v1/history/get_transaction`,
      { id: transactionId }
    );

    return new SingelTransactionModel(result);

    // if(result.statusCode != 200 || !result){
    //   let res:any = await BaseServices.getData(`${Config.historyService}/v2/history/get_transaction?id=${transactionId}`)
    //   console.log("service result ===>" , res);
    //   return new SingelTransactionModel(res)
    // }else{
    //   console.log("service else result ===>" , result)
    //   // TODO:check resoult structure json
    //   return new SingelTransactionModel(result)
    // }
  }

  static async getBlock(block: number): Promise<BlockModel> {
    let blockInfo = await BaseServices.postData(
      `${Config.mainService}/v1/chain/get_block`,
      { block_num_or_id: block }
    );
    return new BlockModel(blockInfo);
  }
}
