export default class SingleTransactionModel
{
    // constructor(data:any){
    //     this.trxId = data.trx_id;
    //     this.lib = data.lib
    //     for(let action of data.actions){
    //         this.actions.push(new setData(action))
    //     }
    // }
    // actions:any[]=[]
    // trxId?:string=''
    // lib?:number


    constructor(data:any=null){    
      if(data == null ) return      
      this.raw = data
      this.blockNumber = data.block_num
      this.blockTime = data.block_time
      this.trxId = data.id
      this.irreversible = data.irreversible ? data.irreversible : false
      this.cpuUsage = data.trx.receipt.cpu_usage_us
      this.netUsage = data.trx.receipt.net_usage_words * 8
      this.status = data.trx.receipt.status
      // this.traces = data.traces
      this.setTraces(data.traces)
    }

    setTraces(traces){
      let t = JSON.stringify(traces)
      let trcs = JSON.parse(t)
      for(var trace of trcs){
        this.checkSeq(trace , trcs)
      }
      this.traces = trcs
    }

    checkSeq(trc , traces){
      this.traceCount++
      let trcs = traces
      if(trc.inline_traces.length > 0){
        for(var trace of trc.inline_traces){
          let find = trcs.find(tr => tr.receipt.global_sequence == trace.receipt.global_sequence)
          if(find){
            let indexOf = trcs.indexOf(find)
            trcs.splice(indexOf , 1)
          }
          this.checkSeq(trace , trcs)
        }
      }
    }

    blockNumber?:number
    blockTime?:string = ''
    trxId?:string=''
    irreversible?:boolean = false
    cpuUsage?:number
    netUsage?:number
    status?:string
    raw?:any
    traces?:any
    traceCount:number = 0

} 

export class setData
{
    constructor(data:any){
        this.name = data.act.name
		this.trxId = data.trx_id
		this.account = data.act.account
        this.data= this._ConvertKababCaseToCamelCase(data.act.data)
        
    }
    name?:string
    trxId?:string
    account?:string
    data?:any
    private  _ConvertKababCaseToCamelCase(data:any){
        let obj:any = {}
        for(let [key,value] of Object.entries(data)){
            if(key.split('_').length > 1){
                let arr:any = key.split('_') 
				let rest:string=''
				let partOne:string=''
				for(let i in arr){
					partOne = arr[0]
					if(parseInt(i) > 0){
						rest += arr[i][0].toUpperCase()+arr[i].slice(1)
					}
				}
				obj[partOne+rest] = value
            }else{
				obj[key] = value
			}
        }
        return obj
    }
} 

