export default class TransactionsModel
{
    constructor(data:any , accountName:string){
		for(let transaction of data.actions){
            this.transactions.push(new setData(transaction , accountName))
        }
    }
    transactions:setData[]=[]
} 

export class setData
{
    constructor(data:any , accountName:string){        
		this.accountActionSeq = data.account_action_seq
		this.blockTime = data.block_time
		this.trxId = data.action_trace.trx_id
		this.name = data.action_trace.act.name
        this.contract = data.action_trace.act.account
        this.data = this._ConvertKababCaseToCamelCase(data.action_trace.act.data)

        if(this.contract == 'marble.fort' ||
         this.contract == 'market.code' ||
         this.contract == 'lottmrb.code' || 
         this.contract == 'wsmrbl.dstny' ||
         this.contract == 'tlos.tbond' ||
         this.contract == 'tbond.code' ||
         this.contract == 'auction.code'
         ){
            this.style = {
                icon: "fa fa-genderless",
                textClass: "text-areax",
                badgeClass: "badge-light-areax",
                borderClass: "border-areax",
            }
        }

        else if(this.name == 'transfer' && this.data.from == accountName){
            this.style = {
                icon: "bi bi-currency-dollar",
                textClass: "text-danger",
                badgeClass: "badge-light-danger",
                borderClass: "border-danger",
            }
        }

        else if(this.name == 'transfer' && this.data.to == accountName){
            this.style = {
                icon: "bi bi-currency-dollar",
                textClass: "text-success",
                badgeClass: "badge-light-success",
                borderClass: "border-success",
            }
        }

        else{
             {
                this.style = {
                    icon: "fa fa-genderless",
                    textClass: "text-info",
                    badgeClass: "badge-light-info",
                    borderClass: "border-info"
                }
            }
        }
    }
    blockTime?:string
    trxId?:string
    name?:string
    contract?:string
    data?:any
    accountActionSeq:number=0;
    style?:any
    private  _ConvertKababCaseToCamelCase(data:any){
        let obj:any = {}
        for(let [key,value] of Object.entries(data)){
            if(key.split('_').length > 1){
                let arr:any = key.split('_') 
				let rest:string=''
				let partOne:string=''
				for(let i in arr){
					partOne = arr[0]
					if(parseInt(i) > 0){
						rest += arr[i][0].toUpperCase()+arr[i].slice(1)
					}
				}
				obj[partOne+rest] = value
            }else{
				obj[key] = value
			}
        }
        return obj
    }
} 

